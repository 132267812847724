import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { gsap } from "gsap"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import "./index.css"
import Form from "../components/Form"


// Image Assets
import roofing1 from "../images/JC_Roofing_1.jpg"
import roofing2 from "../images/roof.jpg"
import altLogo from "../images/alt-logo.svg"
import map from "../images/map.png"
import prev from "../images/arrow-left.svg"
import next from "../images/arrow-right.svg"
import phone from "../images/phone.svg"

const IndexPage = () => {
const [ testimonial, setTestimonial ] = useState(0)
const testimonials = [
  {
    message: 'We hired J&C Remodeling to repair our leaky roof. The crew was great to work with. Extremely professional, polite, and responsive. We would definitely hire them again.',
    customer: 'Johnson Family',
    city: 'Glen Burnie, MD'
  },
  {
    message: 'My wife and I had a very positive experience with J&C Remodeling. They were very helpful and thorough. They really go above and beyond. Customer service is great!',
    customer: 'The Riveras',
    city: 'Odenton, MD'
  },
  {
    message: 'I used J&C Remodeling for much needed exterior repairs of our home. They were always there to answer any questions I had and kept me informed on the progress. All work was done right on schedule and to my complete satisfaction.',
    customer: 'Stan McCormick',
    city: 'Annapolis, MD'
  },
  {
    message: 'J&C Remodeling did an excellent job on our home improvement projects after we purchased our house. They do honest work while looking out for your best interest throughout the job.',
    customer: 'John Miller',
    city: 'Severn, MD'
  }
]

const settings = {
  dots: false,
  infinite: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: false,
  swipeToSlide: true,
  className: "selector-item",
};

const incrementValue = () => {
  if (testimonial >= testimonials.length - 1) {
    return
  } else {
    setTestimonial(testimonial + 1)
  }
}

const decrementValue = () => {
  if (testimonial === 0) {
    return 
  } else {
    setTestimonial(testimonial - 1)
  }
}

useEffect(() => {
  const words = document.querySelectorAll(".rotator > span")

  let main = gsap.timeline({ repeat: -1 })

  for (let i = 0; i < words.length; i++) {
    let delay = i - 1
    let wordTL = gsap.timeline()
    if (i !== 0) {
      wordTL.from(words[i], {
        duration: 1,
        yPercent: -100,
        opacity: 0,
        ease: "power2.out",
      })
    } else {
      // Handle the first one specially
      delay += 1
      gsap.set(words[0], { opacity: 1, yPercent: 0 })
    }

    if (i !== words.length - 1) {
      wordTL.to(words[i], {
        duration: 1,
        yPercent: 100,
        opacity: 0,
        ease: "power2.out",
      })
    }
    main.add(wordTL, delay)
  }
}, [])

  return (
    <Layout>
    <section>
      <div className="hero">
        <div className="hero-top">
        <h1>
          We Know<span style={{ color: `#CB3100` }} className="rotator">
                <span></span>
                <span>Roofing</span>
                <span>Siding</span>
                <span>Remodeling</span>
                <span>Windows</span>
                <span>Kitchens</span>
                <span>Bathrooms</span>
                <span>Apartments</span>
                <span>Interiors</span>
              </span>
        </h1>
        </div>
        <div className="hero-bottom">
        <p>
          Providing independent contractor services specializing in all types of
          commercial and residential roofing and property renovations.
          <span
            style={{
              fontWeight: `bold`,
              fontStyle: `italic`,
              textDecoration: `underline`,
            }}
          >
          {" "}NO JOB IS TOO BIG OR TOO SMALL!
          </span>
        </p>
        <Link className="cta" to="/contact/">
          FREE ESTIMATE
        </Link>
        <a href='tel:+14436232111' className="mobile-phone-link">
          <img src={phone} alt="phone" />
          <p>(443) 623-2111</p>
        </a>
        </div>
      </div>
    </section>
    <section>
      <div className="content-section">
        <div className="content-section-left">
          <h2>In Business Since 2004</h2>
          <p>
          J&C Remodeling has provided high quality, fast service and great prices 
          to all of your home and business remodeling needs since 2004. We pride 
          ourselves in providing our customers with excellent service and thank you 
          all for your continued business. 
          </p>
        </div>
        <div className="content-section-right">
          <img src={roofing1} alt="roofing" />
        </div>
      </div>

      <div className="content-section-reverse">
        <div className="content-section-left">
          <img src={roofing2} alt="roofing" />
        </div>
        <div className="content-section-right">
          <h2>Quality Service, Fair Price</h2>
          <p>
            We can enhance the beauty, value and durability of any property with
            services that include Kitchen Remodeling, Bathroom Renovation,
            Roofing, Basement Renovation and Home Painting. We are one of the
            leading Maryland home improvement contractors with a great
            reputation both in the community and in the home renovation
            industry.
          </p>
        </div>
      </div>
    </section>

    <section>
      <div className="services-section-mobile">
            <div className="services-section-mobile-top">
              <h1>OUR SERVICES</h1>
            </div>
            <div className="services-section-mobile-bottom">
              <h2>Residential</h2>
              <p>
              Roof installation, tear off, skylights, repairs, leaks, gutters, windows, siding, chimney re-flashing, doors, decks, drywall, kitchen, bathrooms, and basements.
              </p>
              <h2>Commercial</h2>
              <p>
              All types of commercial roofing including but not limited to shingle roofing, TPO, EPDM, Duralast membrane new construction and limited maintenance and service on interior and exterior needs.
              </p>
              <h2>Apartment Maintenance</h2>
              <p>
              Roofs, windows, siding, interior repairs including leaks and drywall.
              </p>
            </div>
      </div>
      <div className="services-section-desktop">
        <div className="services-section-top">
          <h1>OUR SERVICES</h1>
        </div>
        <div className="services-section-middle">
          <div className="service-card">
            <div className="service-card-top">
              <h2>Residential</h2>
            </div>
            <div className="service-card-bottom">
              <p>
              Roof installation, tear off, skylights, repairs, leaks, gutters, windows, siding, chimney re-flashing, doors, decks, drywall, kitchen, bathrooms, basements
              </p>
            </div>
          </div>
          <div className="service-card">
            <div className="service-card-top">
              <h2>Commercial</h2>
            </div>
            <div className="service-card-bottom">
              <p>
              All types of commercial roofing including but not limited to shingle roofing, TPO, EPDM, Duralast membrane new construction and limited maintenance and service on interior and exterior needs.
              </p>
            </div>
          </div>
          <div className="service-card">
            <div className="service-card-top">
              <h2>Apartment Maintenance</h2>
            </div>
            <div className="service-card-bottom">
              <p>
              Roofs, windows, siding, interior repairs including leaks and drywall
              </p>
            </div>
          </div>
        </div>
        <div className="services-section-bottom">
          <Link className="cta" to="/contact/">
            <p>CONTACT US</p>
          </Link>
        </div>
      </div>
    </section>
    <section>
      <div className="map-section">
        <div className="map-section-top">
          <img src={altLogo} alt="company logo" />
        </div>
        <div className="map-section-bottom">
          <div className="map-section-bottom-left">
            <h1>WE’RE LOCAL!</h1>
            <h2>
              We proudly service the greater Baltimore / Washington DC metro
              area including: Anne Arundel County, Prince George's County,
              Montgomery County, Howard County, Baltimore County, Fredrick
              County, Baltimore City, and Washington DC.
            </h2>
            <Link
              style={{ background: `linear-gradient(0deg, #696969, #B8B8B8)` }}
              className="cta"
              to="/contact/"
            >
              <p>CONTACT US</p>
            </Link>
          </div>
          <div className="map-section-bottom-right">
            <img src={map} alt="map" />
          </div>
        </div>
      </div>
    </section>
    <section>
      <div className="testimonials-section">
        <div className="testimonials-section-top">
          <h1>TESTIMONIALS</h1>
        </div>
        <div className="testimonials-section-slider">
          <div className="slider-button-container">
            <div onClick={decrementValue} className="slider-button">
              <img style={{marginRight: `5%`}} src={prev} alt="previous" />
            </div>
          </div>
          <div className="slider-content">
            <p>
            {testimonials[testimonial].message}
            <br/>
            <br/>
            {testimonials[testimonial].customer} - {testimonials[testimonial].city}
            </p>
          </div>
          <div className="slider-button-container">
            <div  onClick={incrementValue}  className="slider-button">
              <img style={{marginLeft: `5%`}} src={next} alt="next" />
            </div>
          </div>
        </div>
        <div className="testimonials-section-dots">
          <div className="dot" style={{background: testimonial === 0 ? `#ed6134` : `#C4C4C4`}}></div>
          <div className="dot" style={{background: testimonial === 1 ? `#ed6134` : `#C4C4C4`}}></div>
          <div className="dot" style={{background: testimonial === 2 ? `#ed6134` : `#C4C4C4`}}></div>
          <div className="dot" style={{background: testimonial === 3 ? `#ed6134` : `#C4C4C4`}}></div>
        </div>
        <div className="testimonials-section-cta">
        <a href="https://www.yelp.com/biz/j-and-c-remodeling-md-glen-burnie" target="_blank" rel="noopener noreferrer" className="cta"><p>LEAVE US A REVIEW</p></a>
        </div>
      </div>
    </section>

    <section>
      <div className="form-section">
        <div className="form-section-top">
          <h1>CONTACT US</h1>
        </div>
        <div className="form-section-bottom">
          <div className="form-section-bottom-left">
            <h3>
            We strive to provide the highest quality roofing and property renovation services in 
            the Baltimore / Washington metro area at the most reasonable price. 
            <br/>
            <br/>
            Get started with us by filling out the form to request your <span style={{color: `#cb3100`}}>FREE ESTIMATE</span>, or 
            give us a call at <span style={{color: `#cb3100`}}>(443) 623-2111</span> and we'll be in touch!
            </h3>
          </div>
          <div className="form-section-bottom-right">
            <Form />
          </div>
        </div>
      </div>
    </section>
  </Layout>
  )
}

export default IndexPage
